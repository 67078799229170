import {
  ECustomRangeType,
  TAbsoluteTimeUnit,
  TRelativeTimeUnit,
} from '@/components/date-range-picker/date-range-picker.ts';

const timeUnits: Record<TRelativeTimeUnit | TAbsoluteTimeUnit, string> = {
  days: 'Tag | Tage',
  hours: 'Stunde | Stunden',
  isoWeek: 'Kalenderwoche | Kalenderwochen',
  minutes: 'Minute | Minuten',
  months: 'Monat | Monate',
  quarters: 'Quartal | Quartale',
  weeks: 'Woche | Wochen',
  years: 'Jahr | Jahre',
};

const timeUnitsShort: Record<TRelativeTimeUnit | TAbsoluteTimeUnit, string> = {
  days: 'T',
  hours: 'Std',
  isoWeek: 'KW',
  minutes: 'Min',
  months: 'M',
  quarters: 'Q',
  weeks: 'W',
  years: 'J',
};

const header: Record<ECustomRangeType, string> = {
  [ECustomRangeType.ABSOLUTE]: 'Absolute',
  [ECustomRangeType.CUSTOM]: 'Custom',
  [ECustomRangeType.RELATIVE]: 'Relative',
};

export default {
  header,
  convertToCustom: 'Zu Benutzerdefiniert umwandeln',
  custom: 'Benutzerdefiniert',
  currentWeek: 'aktuelle Woche',
  previousWeek: 'vorherige Woche',
  nextWeek: 'nächste Woche',
  calendarWeek: 'KW',
  rangeInvalidHint: 'Der gewählte Zeitraum ist entweder größer als der maximal zulässige Zeitraum {maxDuration} oder die gewählten Daten sind außerhalb des zugelassenen bereichs von {minDate} - {maxDate}.',
  rangeFrom: 'Von',
  rangeTo: 'Bis',
  timeUnit: 'Zeiteinheit',
  timeUnits,
  timeUnitsShort,
};
